import React from 'react'
import { makeStyles } from '@mui/styles'
import { breakpoints, colors } from '../db/assetsCss'

const useStyles = makeStyles((theme) => ({
  cardCont: {
    padding: 8,
    borderRadius: 15,
    border: `1px solid ${colors.border}`,
    display: 'flex',
    flexDirection: 'column',
  },
  cardImage: {
    height: 450,
    borderRadius: '15px 15px 0px 0px',
    [theme.breakpoints.down(breakpoints.breakpoint2)]: {
      height: 350,
    },
    [theme.breakpoints.down(breakpoints.breakpoint3)]: {
      width: 300,
      height: 'auto',
    },
  },
  textCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
    padding: '40px 10px 20px 10px',
  },
  iconCardCont: {
    width: 60,
    height: 60,
    backgroundColor: colors.primary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: -30,
    borderRadius: 15,
    [theme.breakpoints.down(breakpoints.breakpoint2)]: {
      width: 50,
      height: 50,
      top: -25,
    },
    [theme.breakpoints.down(breakpoints.breakpoint3)]: {
      width: 60,
      height: 60,
      top: -30,
    },
  },
  icon: {
    width: 38,
    [theme.breakpoints.down(breakpoints.breakpoint2)]: {
      width: 32,
    },
    [theme.breakpoints.down(breakpoints.breakpoint3)]: {
      width: 36,
    },
  },
  textCard: {
    margin: 0,
    fontSize: 20,
    fontWeight: 600,
    textTransform: 'capitalize',
    [theme.breakpoints.down(breakpoints.breakpoint2)]: {
      fontSize: 15,
      fontWeight: 500,
    },
    [theme.breakpoints.down(breakpoints.breakpoint3)]: {
      fontSize: 18,
      fontWeight: 600,
    },
  },
}))

const CardInclusive = ({ props }) => {
  const classes = useStyles()
  return (
    <div className={classes.cardCont}>
      <img
        src={props.image.src}
        alt={props.image.alt}
        className={classes.cardImage}
      />
      <div className={classes.textCont}>
        <div className={classes.iconCardCont}>
          <img src={props.icon} alt="icon" className={classes.icon} />
        </div>
        <p className={classes.textCard}>{props.text}</p>
      </div>
    </div>
  )
}

export default CardInclusive

import React from 'react'
import { makeStyles } from '@mui/styles'
import { breakpoints, padding } from '../db/assetsCss'
const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    padding: padding.desktop,
    boxSizing: 'border-box',
    [theme.breakpoints.down(breakpoints.breakpoint3)]: {
      padding: padding.tablet,
    },
    [theme.breakpoints.down(breakpoints.mobile)]: {
      padding: padding.mobile,
    },
  }
}))

const Terms = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <h1>Strapp Mobile Application Terms and Conditions</h1>
      <p>Last Updated: 17/03/2024</p>

      <ol>
        <li>
          <h2>Acceptance of Terms</h2>
          <p>
            By accessing or using the Strapp mobile application ("the App"), you
            agree to comply with and be bound by these Terms and Conditions. If
            you do not agree with these terms, please do not use the App.
          </p>
        </li>

        <li>
          <h2>Purpose of the App</h2>
          <p>
            Strapp is a medication intake reminder that allows users to set
            alarms for their prescriptions. Users can also add family members to
            receive alarms, add prescriptions containing multiple medications,
            and customize drug names if not found in the database.
          </p>
        </li>

        <li>
          <h2>User Accounts</h2>
          <p>
            a. Account Creation: Users are required to create accounts to access
            the App as patients and can grant access to family members.
          </p>
          <p>
            b. Information Collected: During account creation, the following
            information is collected:
          </p>
          <ul>
            <li>Name</li>
            <li>Family name</li>
            <li>Birthday</li>
            <li>Phone number</li>
            <li>Gender</li>
            <li>Password</li>
          </ul>
        </li>

        <li>
          <h2>Data Collection and Privacy</h2>
          <p>a. Data Collected:</p>
          <ul>
            <li>Prescriptions programmed</li>
            <li>Used medications</li>
            <li>Diseases</li>
            <li>Email</li>
            <li>Birthday</li>
            <li>Gender</li>
            <li>Phone number</li>
            <li>Blood type</li>
            <li>City</li>
          </ul>
          <p>b. Data Usage:</p>
          <ul>
            <li>Improve the App</li>
            <li>Generate statistics</li>
            <li>Aid in health environment improvement</li>
            <li>Assist in medication development</li>
          </ul>
          <p>
            c. Third-Party Data Sharing: Strapp does not share user data with
            third parties.
          </p>
        </li>

        <li>
          <h2>User Responsibilities</h2>
          <p>
            Users are obligated not to compromise the confidentiality,
            integrity, and availability of the App.
          </p>
        </li>

        <li>
          <h2>Content Ownership</h2>
          <p>Strapp does not contain user-generated content.</p>
        </li>

        <li>
          <h2>Intellectual Property</h2>
          <p>a. Protection:</p>
          <ul>
            <li>Unique bracelet connected to the App</li>
            <li>Branding, including logo and colors</li>
            <li>App idea and design</li>
          </ul>
        </li>

        <li>
          <h2>Security Measures</h2>
          <p>a. Data Security:</p>
          <ul>
            <li>Data encryption</li>
            <li>One-Time Password (OTP) usage</li>
            <li>Password protection</li>
          </ul>
        </li>

        <li>
          <h2>Termination of Accounts</h2>
          <p>
            User accounts may be terminated or suspended if the user attempts
            unauthorized access or fails to adhere to stated responsibilities.
          </p>
        </li>

        <li>
          <h2>Updates and Changes</h2>
          <p>
            Users will be informed about updates or changes to the App and its
            terms and conditions via email.
          </p>
        </li>

        <li>
          <h2>Limitation of Liability</h2>
          <p>
            a. Phone Requirement: Users must keep the phone on and in their
            possession for Strapp to function.
          </p>
          <p>
            b. Network Connection: Strapp requires a network connection to
            program or restore prescriptions.
          </p>
        </li>

        <li>
          <h2>Governing Law</h2>
          <p>
            The laws of the Algerian jurisdiction govern the terms and
            conditions of Strapp.
          </p>
        </li>

        <li>
          <h2>Dispute Resolution</h2>
          <p>
            Disputes will be resolved through either a mutually agreed
            settlement or in a court of law.
          </p>
        </li>
      </ol>

      <p>Contact Information:</p>
      <p>
        For any questions or concerns regarding these terms, please contact
        Strapplife at contact@strapp.life.
      </p>
    </div>
  )
}

export default Terms

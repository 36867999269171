import { useTheme, ThemeProvider } from '@mui/material'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import { BrowserRouter } from 'react-router-dom'
import { Routes, Route, Navigate } from 'react-router-dom'
import Landing from './pages/Landing'
import Policy from './pages/Policy'
import Terms from './pages/Terms'

function App() {
  const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
      <div style={{ width: '100%', minHeight: '100vh' }}>
        <Navbar />
        <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Landing />} />
          <Route path="/privacy-policy" element={<Policy />} />
          <Route path="/terms-and-conditions" element={<Terms />} />
          {/* protection for none existing routes */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
        <Footer />
      </div>
    </ThemeProvider>
  )
}

export default App

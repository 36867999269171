import React from 'react'
import { makeStyles } from '@mui/styles'
import { breakpoints, colors, padding } from '../db/assetsCss'
import fb from '../assets/icons/Facebook.png'
import ig from '../assets/icons/Instagram.png'
import linkedin from '../assets/icons/LinkedIn.png'
import email from '../assets/icons/email.png'
import phone from '../assets/icons/phone.png'
import address from '../assets/icons/address.png'
import copyright from '../assets/icons/copyright.png'
import logo from '../assets/strappLogoWhite.png'
// import LanguageContext from '../../context/languageContext'

const useStyles = makeStyles((theme) => ({
  footerCont: {
    width: '100%',
    // padding: '65px 15px',
    boxSizing: 'border-box',
    backgroundColor: colors.primary,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexWrap: 'wrap',

    [theme.breakpoints.down(1150)]: {
      gap: 25,
    },
    padding: padding.desktop,
    [theme.breakpoints.down(breakpoints.breakpoint3)]: {
      padding: padding.tablet,
    },
    // [theme.breakpoints.down(365)]: {
    //   height: 'calc(100vh - 115.27px)',
    // },
    [theme.breakpoints.down(breakpoints.mobile)]: {
      padding: padding.mobile,
    },
  },
  footerLogoText: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    alignItems: 'center',
    gap: 10,
  },
  logo: {
    width: 222,
  },
  text: {
    fontSize: 18,
    fontWeight: 400,
    color: colors.white,
    maxWidth: 382,
    textAlign: 'center',
  },
  infoCont: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 15,
    padding: 10,
  },
  infoTitle: {
    fontSize: 22,
    fontWeight: 700,
    color: colors.white,
  },
  infoElement: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 10,
    cursor: 'pointer',
  },
  elementIcon: {
    width: 28,
    height: 28,
    objectFit: 'contain',
  },
  elementText: {
    fontSize: 20,
    color: colors.white,
    maxWidth: 310,
  },
  breakline: {
    height: 2,
    backgroundColor: colors.white,
    width: '100%',
  },
  rightsCont: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    justifyContent: 'center',
    backgroundColor: colors.primary,
    padding: '10px 0px',
  },
  rightsText: {
    fontSize: 16,
    color: colors.white,
    [theme.breakpoints.down(365)]: {
      fontSize: 14,
    },
  },
  copyrightIcon: {
    width: 20,
    height: 20,
    objectFit: 'contain',
  },
}))

const Footer = () => {
  const classes = useStyles()
  let date = new Date()
  let year = date.getFullYear()
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer')
  }
  const sections = [
    {
      title: { fr: 'Réseaux Sociaux', en: 'Social Networks' },
      elements: [
        {
          icon: fb,
          text: 'Facebook',
          url: 'https://www.facebook.com/strapplife',
        },
        {
          icon: ig,
          text: 'Instagram',
          url: 'https://www.instagram.com/strapplife/',
        },
        {
          icon: linkedin,
          text: 'LinkedIn',
          url: 'https://www.linkedin.com/company/strapplife/',
        },
      ],
    },
    {
      title: { fr: 'Contatcez-Nous', en: 'Contact us' },
      elements: [
        {
          icon: email,
          text: 'contact@strapp.life',
          url: 'mailto:contact@strapp.life',
        },
        {
          icon: phone,
          text: '+213 661 62 96 71',
          url: 'https://wa.me/+213661629671',
        },
        {
          icon: address,
          text: '№ 3, Street M. Ali Boumendjel, El Djazair 16000',
          url: 'https://maps.app.goo.gl/9R1WSiQu7FrxLBdP8',
        },
      ],
    },
  ]
  const content = {
    fr: {
      text: 'Application de Rappels de médicaments',
    },
    en: {
      text: 'Medication Reminder Application',
    },
  }

  const lang = 'en'

  return (
    <>
      <div className={classes.footerCont}>
        <div className={classes.footerLogoText}>
          <img src={logo} alt="logo white" className={classes.logo} />
          <span className={classes.text}>{content[lang].text}</span>
        </div>
        {sections.map((section, index) => (
          <div key={index} className={classes.infoCont}>
            <span className={classes.infoTitle}>{section.title[lang]}</span>
            {section.elements.map((element, index) => (
              <div
                className={classes.infoElement}
                onClick={() => openInNewTab(element.url)}
                key={index}
              >
                <img
                  src={element.icon}
                  alt="element icon"
                  className={classes.elementIcon}
                />
                <span className={classes.elementText}>{element.text}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={classes.breakline} />
      <div className={classes.rightsCont}>
        <span className={classes.rightsText}>All Rights Reserved</span>
        <span className={classes.rightsText}>&#8226;</span>
        <img
          src={copyright}
          alt="copyright icon"
          className={classes.copyrightIcon}
        />
        <span className={classes.rightsText}>&#8226;</span>
        <span className={classes.rightsText}>Strapplife</span>
        <span className={classes.rightsText}>&#8226;</span>
        <span className={classes.rightsText}>{year}</span>
      </div>
    </>
  )
}

export default Footer
// &#8226;

import React from 'react'
import { makeStyles } from '@mui/styles'
import { padding, colors, breakpoints } from '../db/assetsCss'
import logo from '../assets/logoStrapp.png'
import fb from '../assets/facebook.svg'
import ig from '../assets/instagram.svg'
import linkedin from '../assets/linkedin.svg'
import { Button } from '@mui/material'
// import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  navbarContainer: {
    width: '100%',
    padding: padding.navDesk,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 10,
    [theme.breakpoints.down(breakpoints.breakpoint3)]: {
      padding: padding.navTab,
    },
    // [theme.breakpoints.down(365)]: {
    //   flexDirection: 'column',
    //   gap: 15,
    // },
    [theme.breakpoints.down(breakpoints.mobile)]: {
      padding: padding.navMob,
    },
  },
  logo: {
    width: 165,
    userSelect: 'none',
    cursor: 'pointer'
  },
  downloadButton: {
    fontSize: '14px !important',
    padding: '8px 25px !important',
    fontFamily: 'Poppins !important',
    color: `${colors.primary} !important`,
    backgroundColor: `${colors.white} !important`,
    border: `1px solid ${colors.primary} !important`,
    borderRadius: '5px !important',
    textTransform: 'capitalize !important',
    '&:hover': {
      color: `${colors.white} !important`,
      backgroundColor: `${colors.primary} !important`,
    },
    [theme.breakpoints.down(breakpoints.mobile)]: {
      display: 'none !important',
    },
  },
  social: {
    width: 28,
    height: 28,
    cursor: 'pointer',
    userSelect: 'none',
  },
  socialsCont: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
  },
  leftNav: {
    display: 'flex',
    alignItems: 'center',
    gap: 35,
  },
}))

const Navbar = () => {
  const classes = useStyles()
  // const navigate = useNavigate()
  const socials = [
    {
      src: fb,
      alt: 'facebook logo',
      link: 'https://www.facebook.com/strapplife',
    },
    {
      src: ig,
      alt: 'instagram logo',
      link: 'https://www.instagram.com/strapplife/',
    },
    {
      src: linkedin,
      alt: 'linkedin logo',
      link: 'https://www.linkedin.com/company/strapplife/',
    },
  ]
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer')
  }
  return (
    <div className={classes.navbarContainer}>
      <img src={logo} alt="strapp logo" className={classes.logo} onClick={() => {
        window.location.href = '/'
      }}/>
      <div className={classes.leftNav}>
        <Button
          className={classes.downloadButton}
          variant="outlined"
          onClick={() =>
            openInNewTab(
              'https://play.google.com/store/apps/details?id=com.codeguess.strapp',
            )
          }
        >
          Download The App
        </Button>
        <div className={classes.socialsCont}>
          {socials.map((social, index) => (
            <a
              className={classes.social}
              href={social.link}
              key={index}
              target="blank"
            >
              <img
                src={social.src}
                alt={social.alt}
                className={classes.social}
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Navbar
